// src/utils/auth.js
import auth0 from "auth0-js"
import {navigate} from "gatsby"
// import Auth0Lock from "auth0-lock"

const isBrowser = typeof window !== "undefined"

const auth = isBrowser
    ? new auth0.WebAuth({
        domain: process.env.AUTH0_DOMAIN,
        clientID: process.env.AUTH0_CLIENTID,
        redirectUri: process.env.AUTH0_CALLBACK,
        responseType: "token id_token",
        scope: "openid profile email read:current_user_metadata  update:users read:current_user update:current_user_identities update:current_user_metadata"
    })
    : {}

// const lock = isBrowser
//     ? new Auth0Lock(
//         process.env.AUTH0_CLIENTID,
//         process.env.AUTH0_DOMAIN
//     ): {}

const tokens = {
    accessToken: false,
    idToken: false,
    expiresAt: false,
}

let user = {}
let tokenForReuse;

export const isAuthenticated = () => {
    if (!isBrowser) {
        return;
    }

    return localStorage.getItem("isLoggedIn") === "true"
}

export const login = () => {
    if (!isBrowser) {
        return
    }

    auth.authorize();
}

const setSession = (cb = () => {
}) => (err, authResult) => {
    if (err) {
        navigate("/")
        cb()
        return
    }

    if (authResult && authResult.accessToken && authResult.idToken) {
        let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
        tokens.accessToken = authResult.accessToken
        tokens.idToken = authResult.idToken
        tokens.expiresAt = expiresAt
        user = authResult.idTokenPayload


        tokenForReuse = tokens.accessToken;
        localStorage.setItem('token', tokenForReuse);


        console.log(' ****$$ Auth Result ', authResult);
        localStorage.setItem("isLoggedIn", true)
        navigate("/")

        cb()
    }
}

export const handleAuthentication = () => {
    if (!isBrowser) {
        return;
    }

    auth.parseHash(setSession())

}

// export const getUserMetadata = () => {
//     let patient_id;
//     let treatment_center_id;
//     lock.getUserInfo(tokens.accessToken, function(error, profile) {
//         if (!error) {
//             patient_id = profile.user_metadata.Patient_Id;
//             treatment_center_id = profile.user_metadata.Treatment_Center_Id;
//         }
//     });
//     console.log(' Patient Id', patient_id);
//     console.log(' Treatment center ', treatment_center_id);
//     return;
// }

export const getProfile = () => {

    console.log(' Patient Id', user.family_name);
    console.log(' Treatment center ', user.given_name);
    return user
}
export const silentAuth = callback => {
    if (!isAuthenticated()) return callback()
    auth.checkSession({}, setSession(callback))

}

export const logout = () => {
    localStorage.setItem("isLoggedIn", false)
    auth.logout()
}


export const updateUserMetadata = (treatmentcenterId, patientPin, user) => {

    var webAuth = new auth0.WebAuth({
        domain: process.env.AUTH0_DOMAIN,
        clientID: process.env.AUTH0_CLIENTID,
        redirectUri: process.env.AUTH0_CALLBACK,
        responseType: "token id_token",
        audience: `https://dev-ksyqumoa.us.auth0.com/api/v2/`,
        scope: 'update:current_user_identities'
    });

    // var auth0Manage = new auth0.Management({
    //     domain: 'dev-ksyqumoa.us.auth0.com',
    //     token: localStorage.getItem('token')
    // });
    //
    //
    // user.family_name = '12340900';
    // user.given_name = 'werAj12341'
    //
    // const cb = () => {}
    // auth0Manage.patchUserAttributes(user.user.sub,user , function(err, result) {
    //     console.log(localStorage.getItem('token'));
    //     console.log(err);
    // });


    webAuth.checkSession(
        {
            audience: `https://dev-ksyqumoa.us.auth0.com/api/v2/`,
            scope: 'update:current_user_metadata'
        }, function (err, result) {
            // use result.accessToken
            var auth0Manage = new auth0.Management({
                domain: 'dev-ksyqumoa.us.auth0.com',
                token: localStorage.getItem('token')
            });

            var userMetadata = {
                "family_name": patientPin,
                "given_name": treatmentcenterId
            };
            const cb = () => {
            }
            auth0Manage.patchUserMetadata(user.sub, userMetadata, function (err, result) {
                console.log(err);
            });
        });
}